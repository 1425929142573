
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.MuiTextField-root > label {
  color: gray;
}

.MuiTextField-root > .MuiFormLabel-root.Mui-focused {
  color: black;
}

.MuiDataGrid-columnHeaderTitle {
    /*For some reason, the headers are forced to a weight of 500 via --unstable_DataGrid-headWeight*/
    font-weight: bold !important;
}

.MuiStepLabel-label.MuiStepLabel-label {
  font-size: 1.1rem;
}

.MuiFormHelperText-root.MuiFormHelperText-root {
  font-size: 1rem;
}

.modalList .MuiListItem-root {
  padding-top: 0;
  padding-bottom: 0;
}

.dropzone-label-button {
  background: none;
  border: none;
  color: #646C7F;
  cursor: pointer;
  font: inherit;
  padding: 0;
}

.files-ui-footer.files-ui-footer {
  color: black;
}



